import useSWR from 'swr';

import { Validation } from '@/lib/models/validation';

export function useValidation() {
  const { data, error } = useSWR<Validation>(
    'https://webapi.mzmv.intervista.ch/api/App/getValidation'
    // '/api/getValidation'
  );

  // on error forward

  return {
    validation: data,
    isLoading: !error && !data,
    isError: error,
  };
}
