import { ReactNode } from 'react';

import Navbar from '../ui/NavBar';

interface LayoutProps {
  children: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

const Layout = ({ children, left = undefined, right = undefined }: LayoutProps) => {
  return (
    <main className="relative flex flex-col h-screen bg-grey">
      <Navbar color="red" left={left} right={right} />
      <section className="flex flex-col h-[calc(100%_-_55px)] p-5 pt-8">{children}</section>
    </main>
  );
};

export default Layout;
