import { useContext } from 'react';

import classNames from 'classnames';

import { AppContext } from '@/lib/context/appContext';

type NavProps = {
  color?: 'red' | 'blue' | 'grey';
  percent?: number;
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const NavBar = ({ left, right, percent, color = 'red' }: NavProps) => {
  const { title } = useContext(AppContext);

  // this doesnt work with JIT compiling funny enough
  const headerClasses = `text-lg bg-white justify-between relative flex px-4 border-b-[3px] py-3 pt-4 overflow-hidden`;
  return (
    <div className="sticky top-0 z-10">
      <div
        className={classNames(headerClasses, color == 'red' ? 'border-red' : '', color == 'blue' ? 'border-blue' : '')}
      >
        <div className="flex-1">{left && <>{left}</>}</div>
        <h1 className="text-base font-bold tracking-[0.07em] shrink-0 ">{title}</h1>
        <div className="flex justify-end flex-1">{right && <>{right}</>}</div>
        {percent && (
          <div className="absolute -bottom-[3px] left-0 h-[3px] bg-blue" style={{ width: `${percent}%` }}></div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
